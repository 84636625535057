import type { VueKeyCloakInstance } from '@/@types/keycloak'
import { ThingApiClient } from '@/api/klempner/apiClients/ThingApiClient'

/**
 * A UI Message Handler that sends the message to Ditto and shows notifications afterwards
 */
export default class DittoMsgHandler {
  private api: ThingApiClient

  constructor($keycloak: VueKeyCloakInstance) {
    this.api = new ThingApiClient(() => $keycloak.token)
  }

  /**
   * Sends the given message to Ditto to change a value of the thing
   */
  async sendMessage(thingId: string, msg: DittoFeatureMessage): Promise<void> {
    await this.api.updateProperty(
      thingId,
      msg.featureName,
      msg.propertyType,
      msg.propertyName,
      msg.payload,
    )
  }
}

export interface DittoFeatureMessage {
  readonly featureName: string

  readonly propertyType: string

  readonly propertyName: string

  readonly payload: object | string
}
