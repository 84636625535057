import type IUnitHandler from '@/modules/units/handlers/IUnitHandler'
import { AutoScale } from '@/modules/charts/AutoScale'
import { SUPPORTED_UNITS } from '@/modules/units/handlers/TimeHandler'

const convert = require('@othermo/convert-units')

const RESERVED_FOR_TIME_HANDLER = SUPPORTED_UNITS

/**
 * Handles formatting and scaling of physical quantities
 */
export default class PhysicalQuantityHandler implements IUnitHandler {
  format(type: string, unit: string, value: any, locale = 'de-DE'): string {
    if (!convert().possibilities().includes(unit)) {
      throw new Error(`Unit ${unit} not known`)
    }

    const converted = convert(Number(value)).from(unit).toBest()

    const formatNumber = converted.val?.toLocaleString(locale, {
      minimumFractionDigits: converted.decimalPlaces ?? 1,
      maximumFractionDigits: converted.decimalPlaces ?? 1,
    })

    return formatNumber + ' ' + (converted.symbol || converted.unit)
  }

  isSupported(type: string, unit: string): boolean {
    const possibilities = convert().possibilities()
    return (
      (type === 'float' || type === 'long' || type == 'double') &&
      possibilities.includes(unit) &&
      !RESERVED_FOR_TIME_HANDLER.includes(unit)
    )
  }

  public getScaling(series: [string, number | null][][], unit: string) {
    const preciseBounds = AutoScale.getAxisBounds(series)
    const highestMagnitude = Math.max(Math.abs(preciseBounds.max), Math.abs(preciseBounds.min))

    const best = convert(highestMagnitude).from(unit).toBest()
    const base = convert().getUnit(unit)

    const scalingFactor = base.unit.to_anchor / best.to_anchor
    return { best: { ...best, min: preciseBounds.min, max: preciseBounds.max }, scalingFactor }
  }

  public scaleValue(value: number, scalingFactor: number) {
    return value * scalingFactor
  }

  formatUnit(type: string, unit: string, locale: string): string {
    const unitDefinition = convert().describe(unit)
    return unitDefinition.symbol ?? unitDefinition.abbr
  }

  isDifferentiable(): boolean {
    return true
  }
}
