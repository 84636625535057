import { ApiClient } from '@/api/klempner/apiClients/ApiClient'
import type { Thing } from '@/api/klempner/models/SiteListResponse'
import type { ThingPatchParameters } from '@/api/klempner/apiClients/SiteFilterOptionsBuilder'

export class ThingApiClient extends ApiClient {
  constructor(tokenProvider: () => string) {
    super(tokenProvider)
  }

  async list(
    siteId: string,
    options?: {
      filterCategory?: string | string[]
      includeValues?: boolean
    },
  ): Promise<Thing[]> {
    const request = await this.doRequest(`site/${siteId}/things`, 'GET', options)
    return await request.json()
  }

  async count(siteId: string): Promise<number> {
    const request = await this.doRequest(`site/${siteId}/things/count`, 'GET')
    return await request.json()
  }

  async fetchById(id: string): Promise<Thing> {
    const request = await this.doRequest(`thing/${id}`, 'GET')
    return await request.json()
  }

  public async updateMetadata(thingId: string, data: ThingPatchParameters): Promise<void> {
    await this.doRequest(`thing/${thingId}`, 'PATCH', {}, data)
  }

  public async updateProperty(
    thingId: string,
    featureName: string,
    propertyType: string,
    propertyName: string,
    value: unknown,
  ): Promise<void> {
    await this.doRequest(
      `things/${thingId}/features/${featureName}/${propertyType}/${propertyName}`,
      'PATCH',
      {},
      value,
    )
  }

  async fetchMetadata(
    references: {
      thingId: string
      featureName: string
      propertyType: string
      propertyName: string
    }[],
  ): Promise<{ type: string; unit: string }[]> {
    const request = await this.doRequest(`things/metadata`, 'POST', {}, references)
    return await request.json()
  }
}
